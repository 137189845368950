import React, {useState} from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import "./contact.scss";

const Contact = ({lang}) => {

    let [name, setName] =  useState("");
    let [phone, setPhone] = useState("");
    let [email, setEmail] = useState("");
    let [message, setMessage] = useState("");
    let [acceptance, setAcceptance] = useState(false);

    //Image
    const image = useStaticQuery(graphql`
        query {
            contact: file(relativePath: { eq: "consultoria_empresarial_en_monterrey.jpg" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    let data = {
        "es" : {
            "title" : "Contáctanos",
            "text" : <p>Este es el inicio de una <span>cooperación exitosa.</span></p>,
            "fields" : {
                "name" : {slug: "Nombre"},
                "phone" : {slug: "Teléfono"},
                "email" : {slug: "E-mail"},
                "message": {slug: "Mensaje"}
            },
            "checkText": "Al pulsar enviar acepta que personal de CIE se ponga en contacto usted.",
            "checkLink": "Aviso de privacidad",
            "indication": "Escriba su",
            "privacyLink" : "/privacidad/",
            "buttonText" : {
                "inactive" : "Enviar",
                "active" : "Enviando",
                "done" : "Enviado"
            }
        },
        "en": {
            "title": "Contact us",
            "text": <p>This is the beginning of a <span>successful cooperation.</span></p>,
            "fields": {
                "name": { slug: "Name" },
                "phone": { slug: "Phone" },
                "email": { slug: "E-mail" },
                "message": { slug: "Message" }
            },
            "checkText": "By clicking send you allow CIE personal to contact with you.",
            "checkLink": "Privacy Policy",
            "indication" : "Write your",
            "privacyLink" : "/en/privacy/",
            "buttonText": {
                "inactive": "Send",
                "active": "Sending",
                "done": "Sent"
            }
        }
    }

    return(
        <section className="contact" id="contact">
            <div className="text">
                <h2>{data[lang].title}</h2>
                {data[lang].text}
                <form name="contact" method="POST" action={"/success?lang=" + lang} data-netlify="true" data-netlify-honeypot="bot-field" role="form" aria-label={lang == "es" ? "Informacion de Contacto" : "Contact Information"} >
                    <input type="hidden" name="form-name" value="contact" />
                    <label hidden for="name" id="lname" > {data[lang].indication} {data[lang].fields.name.slug}</label>
                    <input 
                        required 
                        type="text" 
                        id="name" 
                        autocorrect="on" 
                        name={data[lang].fields.name.slug} 
                        placeholder={data[lang].fields.name.slug}
                        autoComplete="name" 
                        aria-placeholder={data[lang].fields.name.slug} 
                        aria-labelledby="lname" 
                        aria-required="true" 
                        aria-autocomplete="inline" 
                    />

                    <label hidden for="phone" id="lphone" > {data[lang].indication} {data[lang].fields.phone.slug}</label>
                    <input 
                        required 
                        type="number" 
                        minlength="10" 
                        className="no-arrow" 
                        id="phone" 
                        name={data[lang].fields.phone.slug}
                        placeholder={data[lang].fields.phone.slug}
                        autoComplete="tel" 
                        aria-placeholder={data[lang].fields.phone.slug}
                        aria-label={`${data[lang].indication} ${data[lang].fields.phone.slug}`} 
                        aria-labelledby="lphone" 
                        aria-required="true" 
                        aria-autocomplete="inline"
                        role="textbox"
                    />

                    <label hidden for="email" id="lemail" > {data[lang].indication} {data[lang].fields.email.slug}</label>
                    <input 
                        required 
                        type="email" 
                        autocorrect="off" 
                        id="email" 
                        name={data[lang].fields.email.slug} 
                        aria-placeholder={data[lang].fields.email.slug} 
                        placeholder={data[lang].fields.email.slug} 
                        aria-labelledby="lemail"
                        aria-label={`${data[lang].indication} ${data[lang].fields.email.slug}`}
                        aria-required="true" 
                        autoComplete="email" 
                        aria-autocomplete="inline"
                    />

                    <label hidden for="message" id="lmessage" > {data[lang].indication} {data[lang].fields.message.slug}</label>
                    <textarea 
                        required 
                        id="message" 
                        name={data[lang].fields.message.slug} 
                        aria-placeholder={data[lang].fields.message.slug} 
                        placeholder={data[lang].fields.message.slug} 
                        aria-labelledby="lmessage" 
                        aria-required="true" 
                    />

                    <div className="input-el">
                        <label for="acceptance" id="lacceptance"> {data[lang].checkText} <Link to={data[lang].privacyLink}>{data[lang].checkLink}</Link> </label>
                    </div>

                    <div className="input-el">
                        <button role="button" type="submit">{data[lang].buttonText.inactive}</button>
                    </div>

                </form>
            </div>
            <Img fluid={image.contact.childImageSharp.fluid} className="img" loading="lazy"  alt=""/>
        </section>
    );
};

export default Contact;