import React, { useRef } from 'react';
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
//Icons
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

import "./customer.scss";


const Customer = ({bg, title, logo, index, next, back, length}) => {


    return(
        <BackgroundImage fluid={bg} className="customer" id={"c" + index} >
            <div className="overlay"></div>
            <h3>{title}.</h3>
            <Img fluid={logo} className="img" />
        </BackgroundImage>
    )
}

export default Customer;


{/**

            <button role="button" onClick={moveBack} className={"link link-left " + [index > 0 ? "" : "link-disabled"]} > <BsArrowLeft size="32" className="icon" /> <span>{back}</span> </button>
            <button role="button" onClick={moveNext} className={"link link-right " + [index < length - 1 ? "" : "link-disabled"]} > <BsArrowRight size="32" className="icon" /> <span>{next}</span>  </button>
*/}