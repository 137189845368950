import React, { useRef, useState, useEffect} from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Img from "gatsby-image";

//Styles
import "./services.scss";

//Icons
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";


const Services = ({lang}) => {

    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                console.log(entry);

                if (entry.isIntersecting) {
                    //do your actions here
                    console.log('It works!')

                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 1
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
    }, [ref]);

    const images = useStaticQuery(graphql`
        query {
            allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "services"}}, sort: {fields: name}){
                edges{
                    node{
                        childImageSharp{
                            fluid(maxWidth:500, quality: 90){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    //Data
    let data = {
        "es" : {
            title: "Áreas de su empresa en las que podemos fortalecerlos.",
            text: "De manera enunciativa, no limitativa, las áreas y procesos en los que los podemos apoyar y fortalecer son:",
            back: "Atrás",
            next: "Siguiente",
            services: [
                {
                    name: "Apertura de empresas",
                    id: "apertura",
                    next: "administracion",
                    content: <p>Desde la determinación y obtención de los permisos de uso de nombre Gestión en la notaría para formalización de Acta Constitutiva, Agilización de procesos administrativos en diferentes dependencias Cooperación en la determinación y optimización del objeto social de la empresa, así como generar la mayor cantidad de opciones para que puedan quedar cubiertas la mayor cantidad de posibilidades de negocios aplicables. Análisis de posibilidades y riesgos de los socios y las implicaciones legales, fiscales y demás de sus componentes.</p>
                },
                {
                    name: "Administración",
                    id: "administracion",
                    before: "apertura",
                    next: "fiscal",
                    content: <p>
                        De conformidad a sus necesidades y al análisis que hagamos de sus procesos, pretendemos apoyar y aportar ideas, sugerencias e implementar estrategias específicas para su área administrativa, de modo que sus procesos resulten ser mejorados y con ello reducir los posibles impactos en costos, gastos e impuestos, relativos al manejo de conceptos, pagos y conceptos de manejo; uno de nuestros enfoques de experiencia, es el correcto manejo, control y aplicación de las mejores soluciones posibles en el concepto de nóminas y remuneraciones, así como el correcto manejo y control de la generación y pagos de impuestos relativos a las mismas, de modo que optimicemos junto con la empresa, uno de los conceptos más difíciles de controlar y manejar de manera adecuada.
                                <span><br />Del mismo modo, lo anterior, se aplica para el resto de los conceptos de manejo que tengan para su empresa, incluyendo el correcto manejo de su contabilidad, registro y control de operaciones, desde el análisis de sus procesos, obligaciones contractuales y manejo adecuado de contabilidad y reportes administrativos para que puedan contar con información adecuada y oportuna para la mejor toma de decisiones de parte de la administración y dirección de la empresa.
                                <br/>
                                Manejo de procesos y controles para el óptimo aprovechamiento de sus recursos, tanto materiales, como humanos, y para el correcto manejo y utilización de sus activos tangibles e intangibles, para que de este modo la empresa tenga un manejo más eficiente y apegado al cumplimiento de las obligaciones que le son inherentes, no solo reduciendo su impacto, sino optimizándolo al igual que sus resultados</span></p>
                },
                {
                    name: "Fiscal",
                    id: "fiscal",
                    before: "administracion",
                    next: "imms",
                    content: <p className="p">
                                <p>Analizamos cumplimiento de obligaciones y correcta presentación de sus obligaciones y declaraciones.</p>
                                <p><b>Asesoría fiscal</b></p>
                                <ul>
                                    <li>  - Consultoría fiscal especializada.</li>
                                    <li>  - Estrategias Fiscales.</li>
                                    <li>  - Revisión del cumplimiento de estrategias fiscales.</li>
                                    <li>  - Optimización de carga tributaria.</li>
                                </ul>
                                <p><b>Defensa fiscal</b></p>
                                <ul>
                                    <li>  - Litigio fiscal.</li>
                                    <li>  - Consultas y confirmaciones de criterio ante el SAT.</li>
                                    <li>  - Asesoría y defensa fiscal ante cualquier autoridad fiscal (SAT, IMMS, Infonavit, Tesorería Estatal).</li>
                                    <li>  - Acuerdos exclusivos en PRODECON.</li>
                                </ul>
                            </p>
                },
                {
                    name: "IMMS",
                    id: "imms",
                    before: "fiscal",
                    next: "legalcorporativo",
                    content: <p>Asesoramos y coadyuvamos con la empresa, en sus procesos, desde la afiliación – vigencia, hasta los procesos de determinación de cuotas y pagos, asesoramos y proveemos soluciones de manejo eficiente de nómina y sus impuestos inherentes, bajo un esquema de control y seguridad para la empresa, manejo correcto de incidencias<span> pues en muchas de las ocasiones, nos percatamos que por falta de tiempo y de conocimiento de sus departamentos de nóminas o equivalentes, no cuidan este renglón, lo que impacta negativamente en los resultados de la empresa</span>, así como de igual modo, tenemos contadores certificados para la realización de dictámenes de todo tipo; así como manejo de SATIC / SIROC, para aquellas empresas con procesos de construcción, que tengan la obligación, desde sus procesos administrativos, hasta la consecución de Oficios de Diferencias y Conclusión en las mejores condiciones económicas para la empresa y sus proyectos. <Link to="/imms" className="link">Ver más ...</Link> </p>
                },
                {
                    name: "Legal Corporativo",
                    id: "legalcorporativo",
                    before: "imms",
                    next: "legal",
                    content: <p>Para la apertura y proyecto de creación de empresas, les ofrecemos un análisis en conjunto con los socios, para la determinación de las mejores opciones de manejo estructural en la fase Legal-Corporativa de la empresa, para proteger sus patrimonios personales de los socios y desarrollar la estructura integral de la compañía, de modo que desde su concepto inicial, desarrollemos un esquema legal lo más cuidado y diseñado de conformidad con las expectativas de sus procesos y manejo formal. Así mismo, en el caso de las empresas ya formalizadas previamente, desarrollamos un análisis de su estructura, para encontrar posibles fallas u opciones de mejora para la empresa, sus socios y los terceros que puedan tener alguna afectación por la conformación original de la empresa. <span>De modo que podemos ofrecer soluciones y estrategias legales que les permitan estar con la tranquilidad de que sus recursos y activos, están totalmente protegidos y optimizarlos para usted.</span></p>
                },
                {
                    name: "Legal",
                    id: "legal",
                    before: "legalcorporativo",
                    next: "financiera",
                    content: <p>Podemos ofrecer a las empresas y sus socios, una amplia gama de soluciones en materia legal, en prácticamente todas las ramas de derecho público y privado, para apoyarlos en el manejo de las diferentes situaciones que pueden serle necesarias por las actividades normales de la empresa, así como aquellas que se puedan presentar por las mismas actividades o situaciones especiales que se puedan presentar en la vida activa de la misma. Contamos con diferentes sinergias que nos permiten apoyarlos en prácticamente todas sus necesidades, ya sea de manera preventiva o por evento, dependiendo de las condiciones que se presenten en cada compañía y sus relaciones con terceros.</p>
                },
                {
                    name: "Financiera",
                    id: "financiera",
                    before: "legal",
                    content: <p>Asesoría en el manejo de los recursos económicos de la empresa y de sus socios, en un total control de sus obligaciones fiscales y legales, además de la generación de oportunidades de negocios que le permitan incrementar los resultados positivos de la empresa, o en reducir, los riesgos y posibles impactos negativos en los mismos, mediante el correcto manejo y control de los factores que pudieran impactar en ellos.</p>
                },
            ]
        },
        "en": {
            title: "Areas of your company we can strength",
            text: "In an enunciative, non- limiting way, the areas and processes in which we can support and strengthen them are:",
            back: "Back",
            next: "Next",
            services: [
                {
                    name: "Apertura de empresas",
                    id: "apertura",
                    next: "administracion",
                    content: <p>Desde la determinación y obtención de los permisos de uso de nombre Gestión en la notaría para formalización de Acta Constitutiva, Agilización de procesos administrativos en diferentes dependencias Cooperación en la determinación y optimización del objeto social de la empresa, así como generar la mayor cantidad de opciones para que puedan quedar cubiertas la mayor cantidad de posibilidades de negocios aplicables. Análisis de posibilidades y riesgos de los socios y las implicaciones legales, fiscales y demás de sus componentes.</p>
                },
                {
                    name: "Administración",
                    id: "administracion",
                    before: "apertura",
                    next: "fiscal",
                    content: <p>
                        De conformidad a sus necesidades y al análisis que hagamos de sus procesos, pretendemos apoyar y aportar ideas, sugerencias e implementar estrategias específicas para su área administrativa, de modo que sus procesos resulten ser mejorados y con ello reducir los posibles impactos en costos, gastos e impuestos, relativos al manejo de conceptos, pagos y conceptos de manejo; uno de nuestros enfoques de experiencia, es el correcto manejo, control y aplicación de las mejores soluciones posibles en el concepto de nóminas y remuneraciones, así como el correcto manejo y control de la generación y pagos de impuestos relativos a las mismas, de modo que optimicemos junto con la empresa, uno de los conceptos más difíciles de controlar y manejar de manera adecuada.
                                <span><br />Del mismo modo, lo anterior, se aplica para el resto de los conceptos de manejo que tengan para su empresa, incluyendo el correcto manejo de su contabilidad, registro y control de operaciones, desde el análisis de sus procesos, obligaciones contractuales y manejo adecuado de contabilidad y reportes administrativos para que puedan contar con información adecuada y oportuna para la mejor toma de decisiones de parte de la administración y dirección de la empresa.
                                <br />
                                Manejo de procesos y controles para el óptimo aprovechamiento de sus recursos, tanto materiales, como humanos, y para el correcto manejo y utilización de sus activos tangibles e intangibles, para que de este modo la empresa tenga un manejo más eficiente y apegado al cumplimiento de las obligaciones que le son inherentes, no solo reduciendo su impacto, sino optimizándolo al igual que sus resultados</span></p>
                },
                {
                    name: "Fiscal",
                    id: "fiscal",
                    before: "administracion",
                    next: "imms",
                    content: <p>Analizamos cumplimiento de obligaciones y correcta presentación de sus obligaciones y declaraciones</p>
                },
                {
                    name: "IMMS",
                    id: "imms",
                    before: "fiscal",
                    next: "legalcorporativo",
                    content: <p>Asesoramos y coadyuvamos con la empresa, en sus procesos, desde la afiliación – vigencia, hasta los procesos de determinación de cuotas y pagos, asesoramos y proveemos soluciones de manejo eficiente de nómina y sus impuestos inherentes, bajo un esquema de control y seguridad para la empresa, manejo correcto de incidencias<span> pues en muchas de las ocasiones, nos percatamos que por falta de tiempo y de conocimiento de sus departamentos de nóminas o equivalentes, no cuidan este renglón, lo que impacta negativamente en los resultados de la empresa</span>, así como de igual modo, tenemos contadores certificados para la realización de dictámenes de todo tipo; así como manejo de SATIC / SIROC, para aquellas empresas con procesos de construcción, que tengan la obligación, desde sus procesos administrativos, hasta la consecución de Oficios de Diferencias y Conclusión en las mejores condiciones económicas para la empresa y sus proyectos.  <Link to="/en/imms">Read more ...</Link>  </p>
                },
                {
                    name: "Legal Corporativo",
                    id: "legalcorporativo",
                    before: "imms",
                    next: "legal",
                    content: <p>Para la apertura y proyecto de creación de empresas, les ofrecemos un análisis en conjunto con los socios, para la determinación de las mejores opciones de manejo estructural en la fase Legal-Corporativa de la empresa, para proteger sus patrimonios personales de los socios y desarrollar la estructura integral de la compañía, de modo que desde su concepto inicial, desarrollemos un esquema legal lo más cuidado y diseñado de conformidad con las expectativas de sus procesos y manejo formal. Así mismo, en el caso de las empresas ya formalizadas previamente, desarrollamos un análisis de su estructura, para encontrar posibles fallas u opciones de mejora para la empresa, sus socios y los terceros que puedan tener alguna afectación por la conformación original de la empresa. <span>De modo que podemos ofrecer soluciones y estrategias legales que les permitan estar con la tranquilidad de que sus recursos y activos, están totalmente protegidos y optimizarlos para usted.</span></p>
                },
                {
                    name: "Legal",
                    id: "legal",
                    before: "legalcorporativo",
                    next: "financiera",
                    content: <p>Podemos ofrecer a las empresas y sus socios, una amplia gama de soluciones en materia legal, en prácticamente todas las ramas de derecho público y privado, para apoyarlos en el manejo de las diferentes situaciones que pueden serle necesarias por las actividades normales de la empresa, así como aquellas que se puedan presentar por las mismas actividades o situaciones especiales que se puedan presentar en la vida activa de la misma. Contamos con diferentes sinergias que nos permiten apoyarlos en prácticamente todas sus necesidades, ya sea de manera preventiva o por evento, dependiendo de las condiciones que se presenten en cada compañía y sus relaciones con terceros.</p>
                },
                {
                    name: "Financiera",
                    id: "financiera",
                    before: "legal",
                    content: <p>Asesoría en el manejo de los recursos económicos de la empresa y de sus socios, en un total control de sus obligaciones fiscales y legales, además de la generación de oportunidades de negocios que le permitan incrementar los resultados positivos de la empresa, o en reducir, los riesgos y posibles impactos negativos en los mismos, mediante el correcto manejo y control de los factores que pudieran impactar en ellos.</p>
                },
            ]
        },
    }


    return(
        <section className="services" >
            <article>
                <h2>{data[lang].title}</h2>
                <span>{data[lang].text}</span>
                <div className="services-list" ref={ref}>
                    {
                        images.allFile.edges.sort().map((img, index) =>
                            <div className="service" key={index} id={data[lang].services[index].id} >
                                    <Img className="img" fluid={img.node.childImageSharp.fluid} alt={data[lang].services[index].name} />
                                    <div className="overlay">
                                        <div className="links">
                                            <AnchorLink role="button" to={"#" + data[lang].services[index].before} className={"link " + [index > 0 ? "" : "link-disabled"]} > <BsArrowLeft size="32" /> {data[lang].back} </AnchorLink>
                                            <AnchorLink role="button" to={"#" + data[lang].services[index].next} className={"link " + [index < data[lang].services.length - 1 ? "" : "link-disabled"]} > {data[lang].next} <BsArrowRight size="32" /> </AnchorLink>
                                        </div>
                                    </div>
                                    <div className="text">
                                        <h3> <span className="numIndicator">{`${index+1}/7`}</span> {data[lang].services[index].name}</h3>
                                        {data[lang].services[index].content}
                                    </div>
                            </div>
                        )
                    }
                </div>
            </article>
        </section>
    );
};


export default Services;
