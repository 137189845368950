import React, {useRef} from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Customer from "./customer";
import Carousel from 'react-elastic-carousel'

//Styles
import "./customers.scss";


const Customers = ({lang}) => {
    
    const images = useStaticQuery(graphql`
        query {
            customersImg: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "customersImg"}}, sort: {fields: name}){
                edges{
                    node{
                        name
                        childImageSharp{
                            fluid(quality: 90){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            customersLogo: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "customersLogo"}}, sort: {fields: name}){
                edges{
                    node{
                        childImageSharp{
                            fluid(quality: 90){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    let data = {
        "es":{
            "title": "Algunos de nuestros clientes",
            "next" : "Siguiente",
            "back" : "Atrás"
        },
        "en": {
            "title": "Some of our customers",
            "next": "Next",
            "back": "Back"
        }
    }

    

    return(
        <Carousel className="customers" outerSpacing={0} disableArrowsOnEnd={true} showArrows={false} enableAutoPlay={true} autoPlaySpeed={5000}>
            {
                images.customersImg.edges.map( (edge, index) => 
                    <Customer 
                        bg={edge.node.childImageSharp.fluid} 
                        title={data[lang].title} 
                        logo={images.customersLogo.edges[index].node.childImageSharp.fluid} 
                        index={index}
                        next={data[lang].next}
                        back={data[lang].back}
                        length={images.customersImg.edges.length}
                    />
                )
            }
        </Carousel>
    );
};

export default Customers;

{/**
to={"#c" + parseInt(index - 1)}
to={"#c" + parseInt(index + 1)}
*/}