import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from 'gatsby-background-image';

//SVG
import Squares from "./../images/squares-sm.svg";

//Styles
import "./us.scss";

const Us = ({lang}) => {

    let texts = {
        "es" : {
            title: "Consultoría especializada para tu negocio",
            subtitle: "¿Quiénes somos?",
            parr1: <p className="who-we-are-text">Somos un grupo de profesionistas y profesionales en diferentes áreas de <b>Negocios, Administración, Finanzas, Legal, Legal-Corporativo, Apertura de Empresas, Impuestos</b> que comprenden entre otros: análisis, determinación, cumplimiento de obligaciones, etc.; <b>Fiscal:</b> análisis de situación de empresas, procesos de cumplimiento de obligaciones y declaraciones, así como la defensa fiscal en todos los rubros; <b>Dictámenes Fiscales SAT, Dictámenes Seguro Social,</b> además de que somos expertos en el manejo de <b>procesos de SATIC / SIROC con el IMSS,</b> para Constructoras, entre otras.</p>,
            line2: "Un equipo tan dedicado como su empresa.",
            parr2: "Nuestra estructura está compuesta por socios de negocios, comerciales, sinergias y alianzas, de expertos en todas y cada una de las materias indicadas, lo que nos permite ofrecer un servicio realmente integral en la solución de las necesidades específicas que tenga su negocio, comprometidos a optimizar sus resultados, permitiéndoles dedicarse a su gestión de negocios, y de este modo, nos permita apoyarlos a que sus áreas de oportunidad se eficienticen y logre reducir sus costos y gastos, y por ende, a incrementar sus utilidades.",
            line3: "Expertos completamente comprometidos.",
            parr3: "Conoce nuestra Misión, Visión y Valores.",
            line4: "Estamos aquí para ayudar.",
            parr4: "No pretendemos quitar el trabajo, ni mucho menos, desplazar a ninguna de las personas que trabajan para su empresa, al contrario, lo que pretendemos es ayudarlos a ser más eficaces y eficientes, apoyándolos en las áreas en las que necesiten mejorar y potencializar sus capacidades, pues entendemos que su capital humano y sus activos fijos son los bienes más preciados y por ende, los cuidaremos para ustedes. Como resultado de la aplicación de nuestros servicios, pretendemos que su personal, mejore en sus procesos, y reduzca significativamente los errores u omisiones en sus actividades que tengan algún impacto en la generación y pago de contribuciones y, por ende, en sus resultados.",
            img1alt: "Negociación entre dos personas",
            img2alt: "Estadísticas financieras",
            im3alt: "Clima laborar colaborativo",
        },
        "en": {
            title: "Especialized consulting for your business",
            subtitle: "¿Who are we?",
            parr1: <p className="who-we-are-text">Somos un grupo de profesionistas y profesionales en diferentes áreas de <b>Negocios, Administración, Finanzas, Legal, Legal-Corporativo, Apertura de Empresas, Impuestos</b> que comprenden entre otros: análisis, determinación, cumplimiento de obligaciones, etc.; <b>Fiscal:</b> análisis de situación de empresas, procesos de cumplimiento de obligaciones y declaraciones, así como la defensa fiscal en todos los rubros; <b>Dictámenes Fiscales SAT, Dictámenes Seguro Social,</b> además de que somos expertos en el manejo de <b>procesos de SATIC / SIROC con el IMSS,</b> para Constructoras, entre otras.</p>,
            line2: "A team as dedicated as your company.",
            parr2: "Nuestra estructura está compuesta por socios de negocios, comerciales, sinergias y alianzas, de expertos en todas y cada una de las materias indicadas, lo que nos permite ofrecer un servicio realmente integral en la solución de las necesidades específicas que tenga su negocio, comprometidos a optimizar sus resultados, permitiéndoles dedicarse a su gestión de negocios, y de este modo, nos permita apoyarlos a que sus áreas de oportunidad se eficienticen y logre reducir sus costos y gastos, y por ende, a incrementar sus utilidades.",
            line3: "Fully committed experts.",
            parr3: "Know our Mission, Vision and Values.",
            line4: "We are here to help.",
            parr4: "No pretendemos quitar el trabajo, ni mucho menos, desplazar a ninguna de las personas que trabajan para su empresa, al contrario, lo que pretendemos es ayudarlos a ser más eficaces y eficientes, apoyándolos en las áreas en las que necesiten mejorar y potencializar sus capacidades, pues entendemos que su capital humano y sus activos fijos son los bienes más preciados y por ende, los cuidaremos para ustedes. Como resultado de la aplicación de nuestros servicios, pretendemos que su personal, mejore en sus procesos, y reduzca significativamente los errores u omisiones en sus actividades que tengan algún impacto en la generación y pago de contribuciones y, por ende, en sus resultados.",
            img1alt: "Negociation between two people",
            img2alt: "Finance statistics",
            im3alt: "Collaborative work climate",        
        }
    }

    //Image
    const data = useStaticQuery(graphql`
        query {
            mapimage: file(relativePath: { eq: "map.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            whoWeImage: file(relativePath: { eq: "consultoria_para_empresas_CIE.jpg" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            compromiseImage: file(relativePath: { eq: "consultoria_para_empresas_monterrey_mexico.jpg" }) {
                childImageSharp {
                    fluid(quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            helpImage: file(relativePath: { eq: "consultoria_para_empresas_en_monterrey.jpg" }) {
                childImageSharp {
                    fluid(quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    
    return(
        <section className="us" id="nosotros">
            <BackgroundImage fluid={data.mapimage.childImageSharp.fluid} className="map-bg" >
                <div className="overlay"></div>
                <h1 className="who-we-are-title">{texts[lang].title}</h1>
                <div className="who-we-are-info">
                    <div className="who-we-are-info-img">
                        <Img fluid={data.whoWeImage.childImageSharp.fluid} className="img" alt={texts[lang].img1alt} />
                    </div>
                    <div className="info">
                        <div className="info-c frst">
                            <h2> <img src={Squares} width="18" height="18" alt=""/> {texts[lang].subtitle}</h2>
                            {texts[lang].parr1}
                        </div>
                        <div className="info-c">
                            <h3>{texts[lang].line2}</h3>
                            <p>{texts[lang].parr2}</p>
                        </div>
                    </div>
                    
                </div>
            </BackgroundImage>
            <div className="compromise">
                <div className="text">
                    <h3>{texts[lang].line3}</h3>
                    <Link to="/mision" className="link">{texts[lang].parr3}</Link>
                </div>
                <div className="compromise-img">
                    <Img fluid={data.compromiseImage.childImageSharp.fluid} className="img" alt={texts[lang].img2alt} />
                </div>
            </div>
            <div className="help">
                <div className="text">
                    <h3>{texts[lang].line4}</h3>
                    <p>{texts[lang].parr4}</p>
                </div>
                <div className="help-img">
                    <Img fluid={data.helpImage.childImageSharp.fluid} className="img" alt={texts[lang].img3alt} />
                </div>
            </div>

        </section>
    );
};

export default Us;