import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import PersonSVG from "./../images/person.svg";
import GraphSVG from "./../images/graph.svg";
//SCSS
import "./cover.scss";


const Cover = ({title, subtitle, text, square1, square2, label}) => {

    //Image
    const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "consulting.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp 
          }
        }
      }
    }
  `)


    return (
        <section className="cover">
          <BackgroundImage fluid={data.cover.childImageSharp.fluid} className="cover-container" >
            <div className="overlay">
              <div className="title">
                <h2> <span className="border"></span> {title} <br /> {subtitle} </h2>
                <div className="title-secondary-text">
                  <span>{text}</span>
                </div>
              </div>
              
              <div className="cover-squares">
                  <div className="square">
                    <img src={PersonSVG} width="80" height="80" alt=""/>
                    <h3>{square1.title}</h3>
                    <span>{square1.text}</span>
                  </div>
                  <div className="square">
                <img src={GraphSVG} width="80" height="80" alt=""/>
                    <h3>{square2.title}</h3>
                    <span>{square2.text}</span>
                  </div>
              </div>

            </div>
          </BackgroundImage>
        </section>
    );
};


export default Cover;